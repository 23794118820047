import {Injectable} from '@angular/core';
import * as dayjs from 'dayjs';

dayjs.extend(require('dayjs/plugin/timezone'));


@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  deltaMs: number;

  constructor() {
  }

  toDuration(duration: number) {
    const hours = Math.trunc(duration / 3600);
    const rem = duration % 3600;

    let res = '';
    if (hours >= 1) {
      res = `${hours} hour${hours > 1 ? 's' : ''} `;
    }

    const mins = rem / 60;
    if (mins >= 1) {
      res += `${mins} mins`;
    }

    return res;
  }

  tzAbbr(ts) {
    const d = dayjs.unix(ts);
    const tzNames = {
      'GMT+7': 'ICT/WIB',
      'GMT+8': 'SGT',
      'GMT+9': 'JST',
      'GMT+9:30': 'ACST',
      'GMT+10': 'AEST',
      'GMT+10:30': 'ACDT',
      'GMT+11': 'AEDT',
      'GMT+12': 'NZST',
      'GMT+13': 'NZDT',
    };
    const tzName = d.format('z');
    return tzNames[tzName] || tzName;
  }

  now() {
    // console.log(dayjs.tz.guess());

    // if (!this.deltaMs) {
    //   const d = dayjs('2021-09-04T15:29:00+08:00');
    //   this.deltaMs = dayjs().diff(d);
    // }
    // return dayjs().subtract(this.deltaMs, 'milliseconds');

    return dayjs();
    // return dayjs('2020-12-01');
  }

  intersects(array1, array2) {
    return array1.filter(value => array2.includes(value)).length > 0;
  }

  languageName(lang: string): string {
    lang = lang.toLowerCase();

    switch (lang) {
      case 'en': return 'English';
      case 'id': return 'Bahasa Indonesia';
      case 'zh-cn': return 'Mandarin';
      case 'zh-tw': return 'Mandarin';
      case 'zh-hk': return 'Cantonese';
      case 'th': return 'Thai';
      case 'vi': return 'Vietnamese';
      case 'ja': return 'Japanese';
      case 'ko': return 'Korean';
      default: return 'English';
    }
  }

  dateRange = (startYYYYMMDD: number, endYYYYMMDD: number, day: boolean = true, year: boolean = false): string => {
    const start = dayjs(startYYYYMMDD.toString(), 'YYYYMMDD');
    const startDate = (day ? start.format('ddd') + ' ' : '') + start.date();
    const end = dayjs(endYYYYMMDD.toString(), 'YYYYMMDD');
    const endDate = (day ? end.format('ddd') + ' ' : '') + end.date();

    let result = '';
    if (start.isSame(end)) {
      result = startDate + ' ' + start.format('MMM');
    } else if (start.month() === end.month()) {
      result = startDate + ' - ' + endDate + ' ' + start.format('MMM');
    } else {
      result = startDate + ' ' + start.format('MMM') + ' - ' + endDate + ' ' + end.format('MMM');
    }

    return year ? result + ', ' + end.format('YYYY') : result;
  }
}
