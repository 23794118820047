import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';

import { CheckMeditatorComponent } from './check-meditator/check-meditator.component';
import { HomeComponent } from './home/home.component';
import { HybridMeetComponent } from './hybrid-meet/hybrid-meet.component';
import { EoiAdvProgramComponent } from './eoi-adv-program/eoi-adv-program.component';


export const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'check-meditator', component: CheckMeditatorComponent, canActivate: [AuthGuard] },
  { path: 'hybrid-meet', component: HybridMeetComponent, canActivate: [AuthGuard] },
  { path: 'eoi/adv-programs', component: EoiAdvProgramComponent, canActivate: [AuthGuard] }
];

export const Routing: ModuleWithProviders = RouterModule.forChild(routes);
