import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material';
import * as dayjs from 'dayjs';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { FormGroupDto } from '../../shared/types/form-group-dto.type';
import { EoiAdvProgramDto } from './eoi-adv-program.dto';
import { UtilsService } from '../../shared/utils.service';
import { BackendService } from '../../shared/backend.service';
import { combineLatest, Observable } from 'rxjs';
import { GSheetService } from '../../shared/gsheet.service';
import { NotificationService } from '../../shared/notification/notification.service';

interface Program {
  name: string;
  date: number;
}

interface Dates {
  bsp: {
    gents: number[],
    ladies: number[],
    selected?: number[],
  };
  shoonya: number[];
}


@Component({
  selector: 'app-eoi-adv-program',
  templateUrl: './eoi-adv-program.component.html',
  styleUrls: ['./eoi-adv-program.component.scss']
})
export class EoiAdvProgramComponent implements OnInit {
  fg: FormGroup;
  user: User;
  showFields: boolean;
  showSubmit = true;
  showGotoRegistration: boolean;
  programsBsp: Program[] = [];
  programsShoonya: Program[] = [];
  dates: Dates;
  submitting = false;
  submitted = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private utils: UtilsService,
    private backend: BackendService,
    private gsheet: GSheetService,
    private ntf: NotificationService,
  ) {
    this.user = this.auth.getUser();
    this.fg = this.fb.group(this.formGroupField);
  }

  ngOnInit() {
    this.loading = true;

    this.fg.patchValue(this.mapToEoiDto());
    this.getPrograms().subscribe(programs => {
      this.dates = {
        bsp: { gents: [], ladies: [] },
        shoonya: []
      };

      programs.forEach(p => {
        if (p.name.includes('Bhava Spandana') && p.name.includes('Gents')) {
          this.dates.bsp.gents.push(p.date);
        } else if (p.name.includes('Bhava Spandana') && p.name.includes('Ladies')) {
          this.dates.bsp.ladies.push(p.date);
        } else if (p.name.includes('Shoonya')) {
          this.dates.shoonya.push(p.date);
        }
      });

      this.loading = false;
    });
  }

  private get formGroupField(): FormGroupDto<EoiAdvProgramDto> {
    return {
      registered: new FormControl('', [Validators.required]),
      certainAttend: new FormControl('', [Validators.requiredTrue]),
      confirmTravel: new FormControl('', [Validators.requiredTrue]),
      finalizedTravel: new FormControl('', [Validators.requiredTrue]),
      ieProgramDetails: new FormControl('', [Validators.required]),
      whyInterested: new FormControl('', [Validators.required]),
      programBsp: new FormControl('', []),
      programShoonya: new FormControl('', []),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      whatsappNumber: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      gender: new FormControl('', [Validators.required]),
    };
  }

  getFormField(fieldName: keyof EoiAdvProgramDto): AbstractControl {
    return this.fg.get(fieldName);
  }

  fieldError(field: AbstractControl): boolean {
    return field.touched && field.hasError('required');
  }

  submitSection1(): void {
    const ctrlRegistered = this.getFormField('registered');
    if (ctrlRegistered.hasError('required')) {
      ctrlRegistered.markAsTouched();
      return;
    }

    if (ctrlRegistered.value) {
      this.showFields = true;
      this.showGotoRegistration = false;
      this.showSubmit = true;
    } else {
      this.showFields = false;
      this.showGotoRegistration = true;
      this.showSubmit = false;
    }
  }

  submit(): void {
    this.fg.markAllAsTouched();
    if (!this.fg.valid) {
      console.error('Form is invalid');
      return;
    }

    const observables: Observable<any>[] = [];
    const scriptId = 'AKfycbyWrjukoHJWaVrG9b0WF6mZbcuPes-BpkiAgCtzoiOvoJVMGeEg9U4GtKOF_DLv_XBX';

    let program = this.getFormField('programBsp').value;
    if (program) {
      const params = {...this.fg.value, program };
      observables.push(this.backend.gformSubmit(scriptId, params));
    }

    program = this.getFormField('programShoonya').value;
    if (program) {
      const params = {...this.fg.value, program };
      observables.push(this.backend.gformSubmit(scriptId, params));
    }

    if (observables.length === 0) {
      this.ntf.error('No program selected');
      return;
    }

    this.submitting = true;
    combineLatest(observables).subscribe(() => {
      this.submitting = false;
      this.showSubmit = false;
      this.submitted = true;
    });
  }

  genderSelected($event: MatRadioChange): void {
    let pgmGender: string;
    let bspDates: number[];

    if ($event.value === 'male') {
      bspDates = this.dates.bsp.gents;
      pgmGender = 'Gents';
    } else {
      bspDates = this.dates.bsp.ladies;
      pgmGender = 'Ladies';
    }

    this.programsBsp = [];
    bspDates.forEach(date => {
      const ed = parseInt(dayjs(`${date}`, 'YYYYMMDD').add(3, 'days').format('YYYYMMDD'), 10);
      this.programsBsp.push({
        date,
        name: `Bhava Spandana (English) (${pgmGender}) ${this.utils.dateRange(date, ed)}`
      });
    });
    this.programsBsp.sort((a, b) => a.date - b.date);

    this.programsShoonya = [];
    this.dates.shoonya.forEach(date => {
      const ed = parseInt(dayjs(`${date}`, 'YYYYMMDD').add(3, 'days').format('YYYYMMDD'), 10);
      this.programsShoonya.push({
        date,
        name: `Shoonya Intensive (English) ${this.utils.dateRange(date, ed)}`
      });
    });

    this.programsShoonya.sort((a, b) => a.date - b.date);
  }

  private mapToEoiDto(): EoiAdvProgramDto {
    return {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      whatsappNumber: this.user.phoneNumber,
      country: this.user.country
    };
  }

  getPrograms() {
    return new Observable<Program[]>(observer => {
      this.gsheet.read('1ogwSxZMXBL0RkgPr2HXKXZf-aBo_QtgClWfVSjXFAII', 'Programs!A:B')
        .subscribe((data: any[]) => {
          const pgms: Program[] = [];
          data.forEach(d => {
            const pgm: Program = {
              date: parseInt(dayjs(d['start date'], 'YYYY-MMM-DD').format('YYYYMMDD'), 10),
              name: d.type,
            };
            pgms.push(pgm);
          });

          observer.next(pgms);
        });
    });
  }
}
