import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { IconsModule } from '../shared/icons/icons.module';
import { MaterialModule } from '../shared/material.module';
import { CheckMeditatorComponent } from './check-meditator/check-meditator.component';
import { HomeComponent } from './home/home.component';
import { Routing } from './meditator.routing';
import { HighlightedDisplayNameComponent } from './home/components/highlighted-display-name/highlighted-display-name.component';
import { HybridMeetComponent } from './hybrid-meet/hybrid-meet.component';
import { ModalModule } from '../shared/modal';
import { RegisterModule } from './register/register.module';
import { EoiAdvProgramComponent } from './eoi-adv-program/eoi-adv-program.component';
import { NewMemberModule } from '../new-member/new-member.module';

@NgModule({
  imports: [
    Routing, CommonModule, FlexModule, MaterialModule, CarouselModule, FormsModule,
    LaddaModule, IconsModule, ModalModule, RegisterModule, ReactiveFormsModule, NewMemberModule
  ],
  declarations: [
    HomeComponent, CheckMeditatorComponent, HighlightedDisplayNameComponent,
    HybridMeetComponent, EoiAdvProgramComponent
  ],
  exports: [HomeComponent, CheckMeditatorComponent, HybridMeetComponent]
})
export class MeditatorModule {}
