export interface SessionType {
  programType: string;
  orientationWebinar?: boolean;
  volunteerMeet?: boolean;
  sadhanaSession?: boolean;
  displayName?: string;
  description?: string;
  img?: string;
  preconditionPracticeIds?: number[];
  preconditionPractices?: string[];
  preconditionProgram?: string;
  gSheetName?: string;
  hideInGettingInvolved?: boolean;
  hideRegistrationTitle?: boolean;
  hideProgramDetails?: boolean;
}

export const SessionTypes: SessionType[] = [
  {
    programType: 'Bhava Spandana - Post Program Meetup',
    orientationWebinar: true,
    preconditionProgram: 'Bhava Spandana',
    description:
      'Open to all, who have attended and volunteered for the recent Bhava Spandana programs in Australia.',
    gSheetName: 'Bhava Spandana',
    // hideInGettingInvolved: true,
  },
  {
    programType: 'Satsang',
    sadhanaSession: true
  },
  {
    programType: 'Bhairavi Sadhana',
    sadhanaSession: true
  },
  {
    programType: 'Shambhavi Mahamudra Practice Q&A',
    sadhanaSession: true
  },
  // {
  //   programType: 'Shambhavi Practice Correction',
  //   sadhanaSession: true
  // },
  {
    programType: 'Shakti Chalana Kriya Practice Q&A',
    sadhanaSession: true,
    preconditionPracticeIds: [18, 19, 21], // SCK, Shoonya, Samyama
    preconditionPractices: ['Shakti Chalana Kriya'],
    gSheetName: 'SCK QnA'
  }
];

export const OrientationWebinars: SessionType[] = [
  {
    programType: 'Booom Buddies Galactic Gathering',
    description:
      'IBooom Buddies Gather!! Get up to date on the latest “Miracle of Mind” news and exciting plans to take this ' +
      'amazing app forward. Become  part of Sadhguru’s vision to create a conscious planet',
    img: 'mom-booom-buddies.jpg'
  },
  {
    programType: 'Miracle of Mind: Join the Movement',
    description:
      'Get up to speed on the app that has hit the world ‘Miracle Of Mind’ by Sadhguru. ' +
      'Become part of Sadhguru’s vision to create a conscious planet. ',
    img: 'mom-app.jpg'
  },
  {
    programType: 'Introduction to Sadhanapada',
    description:
      'Webinar includes glimpses of Sadhanapada program, alumni stories and QnA session.',
    img: 'introductiontosadhanapada.png'
  }
];

export const VolunteerMeets = [
  {
    displayName: 'MahaShivRatri 2023 - Meditator Meet',
    programType: 'MahaShivRatri Meditator Meet',
    description:
      'If you thought this is another run of the mill meet, you are mistaken! MahaShivRatri is the only ' +
      'festival that is a 12 hour nightlong celebration with so many people up on their feet the whole night full ' +
      'with energy. It is in our hands to make this grandest online celebration available to everyone possible!',
    img: 'msr-meet.jpg'
  },
  {
    displayName: 'Meet for Isha Covid Action',
    programType: 'Isha Covid Action', // Should match the subtitle field in CRM Program
    img: 'meet-for-isha-covid-action.jpg',
    description: 'Support our Urgent Need'
  },
  {
    displayName: 'Volunteer Meet for Karma Book (Asia)',
    programType: 'Karma Book (SEA)', // Should match the subtitle field in CRM Program
    img: 'karmabook-sea.jpg',
    description: 'A first time unique opportunity!'
  },
  {
    displayName: 'NEW Avatar of Isha Offerings',
    programType: 'New Avatar of Isha Offerings', // Should match the subtitle field in CRM Program
    img: 'newishaofferings.jpg',
    description: ' Be a part of the change :)'
  },
  {
    displayName: 'Volunteer Meet for IDY 2021',
    programType: 'Volunteer Meet for IDY 2021', // Should match the subtitle field in CRM Program
    img: 'idy-vol-meet.jpg',
    description: 'Lets make International Day of Yoga 2021 huge!'
  },
  {
    displayName: 'Meditators Meet - Important Updates',
    programType: 'Meditators Meet: Important Updates on Inner Engineering',
    img: 'mm.jpg'
  },
  {
    displayName: 'Introduction to Conscious Planet - Live',
    programType: 'Introduction to Conscious Planet - Live', // Should match the subtitle field in CRM Program
    img: 'conscious-planet.jpg',
    description:
      'An initiative to raise individual human consciousness and instil a sense of inclusiveness in humanity. ' +
      'A movement of massive proportions never before seen in Isha. The involvement of every single Isha meditator ' +
      'is needed to make this happen. Join this webinar to find out more.'
  },
  {
    displayName: '100DaysForSoil Asia Pacific Earth Buddy Meet',
    programType: '100DaysForSoil Asia Pacific Earth Buddy Meet', // Should match the subtitle field in CRM Program
    img: 'cp_100daysforsoil.png',
    description: 'Join us for the Asia Pacific Earth Buddy Meet - Ride with Sadhguru'
  },
  // {
  //   displayName: 'Introduction to the Save Soil Movement',
  //   programType: 'Introduction to the Save Soil Movement', // Should match the subtitle field in CRM Program
  //   img: 'CP_SS.jpeg',
  //   description:
  //     'Get started as an Earth Buddy! Join us in this interactive session to know how you can be an Earth Buddy to Save Soil. '
  // },
  // {
  //   displayName: 'Introduction to the Save Soil Movement - In Mandarin',
  //   programType: 'Introduction to the Save Soil Movement - In Mandarin', // Should match the subtitle field in CRM Program
  //   img: 'CP_SS.jpeg',
  //   description:
  //     'Get started as an Earth Buddy! Join us in this interactive session to know how you can be an Earth Buddy to Save Soil. '
  // },
  // {
  //   displayName: 'Introduction to the Save soil movement - In Vietnamese',
  //   programType: 'Introduction to the Save soil movement - In Vietnamese', // Should match the subtitle field in CRM Program
  //   img: 'CP_SS.jpeg',
  //   description:
  //     'Get started as an Earth Buddy! Join us in this interactive session to know how you can be an Earth Buddy to Save Soil. '
  // },
  {
    displayName: 'Introduction to Conscious Planet - Re-Telecast',
    programType: 'Introduction to Conscious Planet - Re-Telecast', // Should match the subtitle field in CRM Program
    img: 'conscious-planet.jpg',
    description:
      'An initiative to raise individual human consciousness and instil a sense of inclusiveness in humanity. ' +
      'A movement of massive proportions never before seen in Isha. The involvement of every single Isha meditator ' +
      'is needed to make this happen. Join this webinar to find out more.'
  },
  {
    programType: 'Margazhi Sadhana EOI',
    displayName: 'Expression of Interes' +
      't to participate in Margazhi Sadhana (From your space or Isha place near you)',
    hideRegistrationTitle: true,
    hideProgramDetails: true,
    description:
      'Margazhi Sadhana - Time for Stability & Balance. A unique sadhana designed by Sadhguru for the month of Margazhi\n',
    img: 'margazhi-sadhana.png',
    hideInGettingInvolved: true,
  },
  {
    programType: 'Isha Ambassador: Raise Human Consciousness',
    displayName: 'Isha Ambassador: Raise Human Consciousness',
    hideRegistrationTitle: true,
    hideProgramDetails: true,
    description:
      'Introduce another human being to a yogic offering or practice offered by Sadhguru, ' +
      'Raise human consciousness and build a conscious planet.',
    img: 'isha-ambassador.jpg',
    hideInGettingInvolved: false,
  },
];
