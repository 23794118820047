import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { BackendService } from '../../shared/backend.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnDestroy {
  user: User;
  authSub: Subscription;
  loadingSub: Subscription;
  loading = false;

  constructor(
    private authService: AuthService,
    private backendService: BackendService
  ) {
    this.authSub = this.authService.authChange.subscribe(authStatus => {
      // console.log('auth changed ' + authStatus);
      if (authStatus) {
        this.authService.checkAuth().subscribe(isAuth => {
          this.user = this.authService.getUser();
          // console.log(this.user);
        });
      } else {
        this.user = null;
      }
    });
    this.loadingSub = this.backendService.loadingChange.subscribe(loading => {
      // console.log(loading);
      this.loading = loading;
    });
  }

  ngOnDestroy(): void {
    this.authSub.unsubscribe();
    this.loadingSub.unsubscribe();
  }

  logout() {
    this.authService.logout();
  }
}
