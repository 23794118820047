import {Component} from '@angular/core';

@Component({
  selector: 'app-intro-to-sadhanapada',
  templateUrl: './intro-to-sadhanapada.component.html',
  styleUrls: ['../../register.component.scss']
})
export class IntroToSadhanapadaComponent {
  constructor() {
  }
}
