import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LaddaModule } from 'angular2-ladda';
import { NewMemberModule } from 'src/app/new-member/new-member.module';
import { MaterialModule } from '../../shared/material.module';
import { ModalModule } from '../../shared/modal';
import { RegisterFundayComponent } from './funday/register-funday';
import { RegisterGuruPurnimaComponent } from './guru-purnima/register-guru-purnima';
import { RegisterMsrComponent } from './msr/register-msr';
import { AnandaAlaiComponent } from './programs/ananda-alai/ananda-alai.component';
import { BeingAVolunteerComponent } from './programs/being-a-volunteer/being-a-volunteer.component';
import { BeyondInnerEngineeringComponent } from './programs/beyond-inner-engineering/beyond-inner-engineering.component';
import { BhairaviCulminationComponent } from './programs/bhairavi-sadhana/bhairavi-culmination.component';
import { BhavaspandanaPpmComponent } from './programs/bhavaspandana-ppm/bhavaspandana-ppm.component';
import { BirthOfDhyanalingaComponent } from './programs/birth-of-dhyanalinga/birth-of-dhyanalinga.component';
import { BondOfGraceComponent } from './programs/bond-of-grace/bond-of-grace.component';
import { CorporateOfferingWorkshopComponent } from './programs/corporate-offering-workshop/corporate-offering-workshop.component';
import { DeepeningHathaYogaComponent } from './programs/deepening-hatha-yoga/deepening-hatha-yoga.component';
import { DigitalVeeraTraining1Component } from './programs/digital-veera-training-1/digital-veera-training-1.component';
import { EarthBuddyCelebrationComponent } from './programs/earth-buddy-celebration/earth-buddy-celebration.component';
import { FriendsOfSoilAwebinarComponent } from './programs/friends-of-soil-a-webinar/friends-of-soil-a-webinar.component';
import { IntroToBhavaSpandanaComponent } from './programs/intro-to-bhava-spandana/intro-to-bhava-spandana.component';
import { IshaVidhyaComponent } from './programs/isha-vidhya/isha-vidhya.component';
import { MahashivratriWebinarComponent } from './programs/mahashivratri-webinar/mahashivratri-webinar.component';
import { MyIEStorySharingWorkshopComponent } from './programs/my-ie-story-sharing-workshop/my-ie-story-sharing-workshop.component';
import { NagaPratishthaWebinarComponent } from './programs/naga-pratishtha-webinar/naga-pratishtha-webinar.component';
import { PlanetChampionTrainingComponent } from './programs/planet-champion-training/planet-champion-training.component';
import { PledgeVideoWorkshopComponent } from './programs/pledge-video-workshop/pledge-video-workshop.component';
import { SadhanapadaWebinarComponent } from './programs/sadhanapada-webinar/sadhanapada-webinar.component';
import { SagesOfIndiaPart1Component } from './programs/sages-of-india-part-1/sages-of-india-part-1.component';
import { SagesOfIndiaPart2Component } from './programs/sages-of-india-part-2/sages-of-india-part-2.component';
import { SanghamitraComponent } from './programs/sanghamitra/sanghamitra.component';
import { SatsangComponent } from './programs/satsang/satsang.component';
import { SaveSoilCorporatePartnershipOutreachComponent } from './programs/save-soil-corporate-&-partnership-outreach/save-soil-corporate-&-partnership-outreach.component';
import { SckPracticeQnaComponent } from './programs/sck-practice-qna/sck-practice-qna.component';
import { SgexTriviaComponent } from './programs/sgex-trivia/sgex-trivia.component';
import { SmmPracticeQnaComponent } from './programs/smm-practice-qna/smm-practice-qna.component';
import { YogaVeeraTraining1Component } from './programs/yoga-veera-training-1/yoga-veera-training-1.component';
import { YogaVeeraTraining2Component } from './programs/yoga-veera-training-2/yoga-veera-training-2.component';
import { RegisterComponent } from './register.component';
import { Routing } from './register.routing';
import { RegisterSatsangComponent } from './satsang/register-satsang.component';
import { RegisterSmmGpComponent } from './smm-guided-practice/register-smm-gp.component';
import { RegisterSmmPcComponent } from './smm-practice-correction/register-smm-pc.component';
import { DaysForSoilAsiaPacificEarthBuddyMeetComponent } from './volunteer-meets/100daysforsoil-asia-pacific-earth-buddy-meet/100daysforsoil-asia-pacific-earth-buddy-meet.component';
import { ConsciousPlanetComponent } from './volunteer-meets/conscious-planet/conscious-planet.component';
import { FreeTnTemplesComponent } from './volunteer-meets/free-tn-temples/free-tn-temples.component';
import { IshaAmbassadorComponent } from './volunteer-meets/isha-ambassador/isha-ambassador.component';
import { IshaCovidActionComponent } from './volunteer-meets/isha-covid-action/isha-covid-action.component';
import { KarmaBookAnzComponent } from './volunteer-meets/karma-book-anz/karma-book-anz.component';
import { KarmaBookSeaComponent } from './volunteer-meets/karma-book-sea/karma-book-sea.component';
import { MargazhiSadhanaComponent } from './volunteer-meets/margazhi-sadhana/margazhi-sadhana.component';
import { MsrMeetComponent } from './volunteer-meets/msr-meet/msr-meet.component';
import { NewIshaOfferingsComponent } from './volunteer-meets/new-isha-offerings/new-isha-offerings.component';
import {
  SukkuSoffeeWithAnIshangaComponent
} from './volunteer-meets/sukku-soffee-with-an-ishanga/sukku-soffee-with-an-ishanga.component';
import { VolMeetIeupdateComponent } from './volunteer-meets/vol-meet-ieupdate/vol-meet-ieupdate.component';
import { MomBooomBuddiesComponent } from './webinars/mom-booom-buddies/mom-booom-buddies.component';
import { MomAppComponent } from './webinars/mom-app/mom-app.component';
import { IntroToSadhanapadaComponent } from './webinars/intro-to-sadhanapada/intro-to-sadhanapada.component';

const components = [
  RegisterComponent,
  BirthOfDhyanalingaComponent,
  SagesOfIndiaPart1Component,
  SagesOfIndiaPart2Component,
  SatsangComponent,
  DigitalVeeraTraining1Component,
  YogaVeeraTraining1Component,
  YogaVeeraTraining2Component,
  SanghamitraComponent,
  RegisterMsrComponent,
  RegisterFundayComponent,
  SmmPracticeQnaComponent,
  SckPracticeQnaComponent,
  FreeTnTemplesComponent,
  SadhanapadaWebinarComponent,
  KarmaBookAnzComponent,
  KarmaBookSeaComponent,
  RegisterSmmPcComponent,
  NewIshaOfferingsComponent,
  IshaCovidActionComponent,
  BhavaspandanaPpmComponent,
  CorporateOfferingWorkshopComponent,
  BeyondInnerEngineeringComponent,
  BondOfGraceComponent,
  AnandaAlaiComponent,
  SgexTriviaComponent,
  IshaVidhyaComponent,
  DeepeningHathaYogaComponent,
  BeingAVolunteerComponent,
  MyIEStorySharingWorkshopComponent,
  ConsciousPlanetComponent,
  PlanetChampionTrainingComponent,
  PledgeVideoWorkshopComponent,
  EarthBuddyCelebrationComponent,
  FriendsOfSoilAwebinarComponent,
  SaveSoilCorporatePartnershipOutreachComponent,
  DaysForSoilAsiaPacificEarthBuddyMeetComponent,
  RegisterSatsangComponent,
  NagaPratishthaWebinarComponent,
  BhairaviCulminationComponent,
  MsrMeetComponent,
  VolMeetIeupdateComponent,
  MahashivratriWebinarComponent,
  SukkuSoffeeWithAnIshangaComponent,
  IntroToBhavaSpandanaComponent,
  RegisterGuruPurnimaComponent,
  RegisterSmmGpComponent,
  MargazhiSadhanaComponent,
  IshaAmbassadorComponent,
  MomBooomBuddiesComponent,
  MomAppComponent,
  IntroToSadhanapadaComponent,
];

@NgModule({
  imports: [
    Routing,
    CommonModule,
    FlexModule,
    MaterialModule,
    LaddaModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    NewMemberModule
  ],
  declarations: components,
  exports: components
})
export class RegisterModule {}
