import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class GSheetService {
  apiKey = 'AIzaSyCarPtkLqnIjhEM-NlPN4s56xluJJS-y98';

  constructor(private http: HttpClient, private backend: BackendService) {}

  read(sheetId: string, range: string) {
    return new Observable<any>(observer => {
      const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${this.apiKey}`;
      this.http.get(`${this.backend.url}/api/tunnelUrl?url=${encodeURIComponent(url)}`).subscribe((data: any) => {
        const res = [];
        const headers = [];
        if (data && data.values) {
          data.values.forEach((row, i) => {
            if (i === 0) {
              row.forEach(cell => {
                headers.push(cell.toLowerCase());
              });
            } else {
              const entry = {};
              row.forEach((cell, j) => {
                entry[headers[j]] = cell;
              });
              res.push(entry);
            }
          });
        }
        observer.next(res);
      });
    });
  }
}

export interface JsonFeed {
  feed: {
    entry: any[];
  };
}

export interface PmPortalEndVideos {
  date: string;
  pm: string;
  duration: string;
}
