import {Component} from '@angular/core';

@Component({
  selector: 'app-mom-booom-buddies',
  templateUrl: './mom-booom-buddies.component.html',
  styleUrls: ['../../register.component.scss']
})
export class MomBooomBuddiesComponent {
  constructor() {
  }
}
