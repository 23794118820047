import {Component, Input} from '@angular/core';
import {Program} from '../../program';

@Component({
  selector: 'app-margazhi-sadhana',
  templateUrl: './margazhi-sadhana.component.html',
  styleUrls: ['margazhi-sadhana.component.scss']
})
export class MargazhiSadhanaComponent {
  @Input() program: Program;
  constructor() {
  }
}
