import { Component, OnInit } from '@angular/core';
import { forEach, sortBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { ApiResponseModel } from '../../shared/api-response.model';
import { BackendService } from '../../shared/backend.service';
import { ModalService } from '../../shared/modal';


class Venue {
  name: string;
  address: string;
  mapLink: string;
}

class Session {
  registrationId?: number;
  center?: string;
  venue?: Venue;
  country?: string;
  status?: string;
  programId: number;
  submitting?: boolean;
  startEpochSec?: number;
  online?: boolean;
  hide?: boolean;
}

@Component({
  selector: 'app-register-hybrid-meet',
  templateUrl: './hybrid-meet.component.html',
  styleUrls: ['./hybrid-meet.component.scss']
})
export class HybridMeetComponent implements OnInit {
  sessionTitle = 'March Monthly Sangha';
  date = 'Sunday, 23 March';
  time = '12:30pm AEDT and 9:30am SGT';
  isInit: boolean;

  inPersonSessionRegistered: Session;
  inPersonSessions: Session[] = [];

  onlineSessions: Session[] = [];
  onlineSessionRegistered: Session;

  user: User;
  selectedSession: any;
  modalId = 'hybrid-meet-session-select';
  programIds = [
    10422,10440,10441,10420,10421,10312,10436,10438,10439,10396,10446,10447,10451,10317
  ];
  cancelling: boolean;
  redirectTimerId: any;
  redirecting: boolean;

  constructor(private backend: BackendService,
              private auth: AuthService,
              private modal: ModalService) {
    this.user = this.auth.getUser();
  }

  ngOnInit() {
    this.isInit = false;
    this.inPersonSessions = [];
    this.onlineSessions = [];

    this.queryPrograms();
  }

  queryPrograms() {
    this.backend.api('program.get', { programIds: this.programIds, getStats: true })
      .subscribe((rsp: ApiResponseModel) => {
        forEach(rsp.object, (p) => {
          if (p.disabled) {
            return;
          }

          if (p.venueProp.displayName.indexOf('Online') !== -1) {
            console.log(`Online session: ${p.programId}`);
            this.onlineSessions.push({
              programId: p.programId,
              startEpochSec: p.sessionTimings[0].start,
              center: p.subtitle,
              online: true
              // hide: p.programId === 8391
            });
          } else {
            const s: Session = {
              programId: p.programId,
              center: p.groupProp.displayName.replace('Isha ', ''),
              venue: {
                name: p.venueProp.displayName,
                address: p.venueProp.address,
                mapLink: p.venueProp.mapLink
              },
              country: p.venueProp.country
            };

            if (p.venueProp.displayName.includes('TBA')) {
              s.venue.name = 'To be confirmed';
              s.venue.address = '';
              s.venue.mapLink = '';
            }

            if (s.venue.address === '') {
              s.status = 'TBA';
            } else if (p.isRegistrationLimitReached) {
              s.status = 'CLOSED';
            } else {
              s.status = 'OPEN';
            }

            this.inPersonSessions.push(s);
          }
        });

        this.backend.api('portal.getRegisteredPrograms', { programIds: this.programIds })
          .pipe(
            finalize(() => {
              this.backend.getCountry().subscribe(
                (country: string) => {
                  this.inPersonSessions = sortBy(this.inPersonSessions, [
                    (c) => c.country !== country,
                    (c) => c.country
                  ]);
                  this.isInit = true;
                });
            })
          )
          .subscribe(
            (rsp2: ApiResponseModel) => {
              delete this.onlineSessionRegistered;
              delete this.inPersonSessionRegistered;

              forEach(rsp2.object, (p) => {
                this.setRegistration(p.programId, p.registrationId);
              });

              const now = dayjs();
              // const now = dayjs('2024-04-28T13:45:00+0800');

              if (this.onlineSessionRegistered) {
                const d = dayjs(this.onlineSessionRegistered.startEpochSec * 1000);
                if (now.isAfter(d.subtract(30, 'minutes'))) {
                  this.redirecting = true;
                  this.backend.api('portal.getWebinarRegistrations', { programIds: this.onlineSessionRegistered.programId })
                    .subscribe(
                      (rsp: ApiResponseModel) => {
                        if (rsp.statusCode === 'SUCCESS' && rsp.object.length > 0) {
                          // console.log(`window.location.href = ${rsp.object[0].externalUrl};`)
                          window.location.href = rsp.object[0].externalUrl;
                        }
                      });
                }
              }
            }
          );
      });
  }

  setRegistration(programId: number, registrationId: number) {
    this.onlineSessionRegistered = this.onlineSessions.find(s => s.programId === programId);
    if (this.onlineSessionRegistered) {
      this.onlineSessionRegistered.registrationId = registrationId;
    } else {
      this.inPersonSessionRegistered = this.inPersonSessions.find(s => s.programId === programId);
      this.inPersonSessionRegistered.registrationId = registrationId;
    }
  }

  register(session: Session) {
    session.submitting = true;
    this.backend.api('portal.register', { programId: session.programId, memberId: this.user.memberId })
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.setRegistration(rsp.object.programId, rsp.object.registrationId);
            this.closeModal();
          }
        }
      );
  }

  openModal(session: Session) {
    this.selectedSession = session;
    this.modal.open(this.modalId);
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

  cancel(regId: number) {
    this.cancelling = true;

    if (this.redirectTimerId) {
      console.log('Cancelling timer for redirect');
      clearTimeout(this.redirectTimerId);
      this.redirectTimerId = null;
    }

    this.backend
      .api('portal.cancelRegistration', { registrationId: regId })
      .pipe(
        finalize(() => {
          this.cancelling = false;
        })
      )
      .subscribe(() => {
        this.ngOnInit();
      });
  }
}
