import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { forEach, sortBy } from 'lodash';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../../auth/user.model';
import { ApiResponseModel } from '../../../shared/api-response.model';
import { BackendService } from '../../../shared/backend.service';
import { ModalService } from '../../../shared/modal';

class Venue {
  name: string;
  address: string;
  mapLink: string;
}

class Session {
  center?: string;
  programId: number;
  submitting?: boolean;
  venue?: Venue;
  country?: string;
  closed?: boolean;
}

@Component({
  selector: 'app-register-msr',
  templateUrl: './register-msr.html',
  styleUrls: ['./register-msr.scss']
})
export class RegisterMsrComponent implements OnInit {

  constructor(private backend: BackendService, private auth: AuthService, private modal: ModalService) {
    this.user = this.auth.getUser();
  }
  user: User;
  isInit: boolean;
  selectedSession: any;
  registeredSession: any;
  isAlreadyRegistered: boolean;
  date = 'Wed 26 Feb, 8pm to 27 Feb, 7am';
  programIds = [
    10077,
    10242,
    10246,
    10245,
    10149,
    10240,
    10152,
    10150,
    10148,
    10233,
    10164,
    10163,
    10228,
    10268,
    10269,
    10230,
    10231,
    10222,
    10270,
    10299,
    10231,
    10285
  ];
  sessions: Session[] = [];
  modalId = 'msr-session-select';

  ngOnInit() {
    this.backend.api('program.get', {programIds: this.programIds, getStats: true})
      .subscribe((rsp: ApiResponseModel) => {
        forEach(rsp.object, (p) => {
          if (p.disabled) {
            return;
          }

          const s: Session = {
            programId: p.programId,
            center: p.groupProp.displayName.replace('Isha ', ''),
            venue: {
              name: p.venueProp.displayName,
              address: p.venueProp.address,
              mapLink: p.venueProp.mapLink
            },
            country: p.venueProp.country,
            closed: p.isRegistrationLimitReached,
          };

          if (p.venueProp.displayName.includes('TBA')) {
            s.venue.name = 'To be confirmed';
            s.venue.address = '';
            s.venue.mapLink = '';
          }

          this.sessions.push(s);
        });

        this.backend.api('portal.getRegisteredPrograms', {programIds: this.programIds})
          .pipe(
            finalize(() => {
              this.backend.getCountry().subscribe(
                (country: string) => {
                  this.sessions = sortBy(this.sessions, [(c) => c.country !== country]);
                  this.isInit = true;
                });
            })
          )
          .subscribe(
            (rsp2: ApiResponseModel) => {
              this.isAlreadyRegistered = rsp2.object && rsp2.object.length > 0;
              _.forEach(rsp2.object, (p) => {
                const registeredProgram = this.getSession(p);
                if (registeredProgram) {
                  this.registeredSession = registeredProgram;
                }
              });
            }
          );
      });
  }

  register(session) {
    session.submitting = true;
    this.backend.api('portal.register', {programId: session.programId, memberId: this.user.memberId})
      .pipe(
        finalize(() => {
          session.submitting = false;
        })
      )
      .subscribe(
        (rsp: ApiResponseModel) => {
          if (rsp.statusCode === 'SUCCESS') {
            this.registeredSession = this.getSession(rsp.object);
            this.closeModal();
          }
        }
      );
  }

  getSession(p) {
    return _.find(this.sessions, (sess) => sess.programId === p.programId);
  }

  openModal(session) {
    this.selectedSession = session;
    this.modal.open(this.modalId);
  }

  closeModal() {
    this.modal.close(this.modalId);
  }

}
