import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as dayjs from 'dayjs';
import { find } from 'lodash';
import { finalize } from 'rxjs/operators';

import { AuthService } from '../../auth/auth.service';
import { User } from '../../auth/user.model';
import { ApiResponseModel } from '../../shared/api-response.model';
import { BackendService } from '../../shared/backend.service';
import { UtilsService } from '../../shared/utils.service';
import { OrientationWebinars, SessionType, SessionTypes, VolunteerMeets } from '../config.data';
import { Program } from './program';

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/duration'));
dayjs.extend(require('dayjs/plugin/advancedFormat'));

@Component({
  selector: 'app-register-satsang',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  user: User;
  isInit: boolean;
  numOpenPrograms = 0;
  program: Program;
  programs: Program[] = [];
  registeredProgram: Program;
  submitting: boolean;
  notAllowed: boolean;
  sessionType: SessionType;
  hideRegistrationTitle: boolean;
  hideProgramDetails: boolean;
  now: string;

  name: string;
  altEmailAddress: string;
  phoneNumber: string;
  programDetails: string;

  submissionKey: string;
  programDetailsSubmitted: boolean;
  checkPractice: string;

  constructor(
    private backend: BackendService,
    private auth: AuthService,
    private route: ActivatedRoute,
    private utils: UtilsService,
    private platformLocation: PlatformLocation
  ) {
    this.user = this.auth.getUser();
  }

  ngOnInit() {
    const programIds = this.route.snapshot.params.programId.split('-');
    this.init(programIds);
    this.now = this.utils.now().toString();

    this.name = this.user.firstName + ' ' + this.user.lastName;
    this.phoneNumber = this.user.phoneNumber;
  }

  init(programIds: number[]) {
    this.backend.api('program.get', { programIds }).subscribe((rsp: ApiResponseModel) => {
      if (rsp.statusCode === 'SUCCESS') {
        this.processPrograms(rsp.object);
        this.checkRegistration(programIds);
      }
    });
  }

  languageName(v): string {
    return this.utils.languageName(v);
  }

  processPrograms(programs: any[]) {
    programs.forEach((p: any) => {
      const sess = p.sessionTimings[0];
      const program: Program = {
        programId: p.programId,
        displayName: p.programTypeProp.displayName,
        subtitle: p.subtitle,
        name: p.programTypeProp.name,
        date: dayjs.unix(sess.start).format('dddd, Do MMM YYYY'),
        time: dayjs.unix(sess.start).format('h:mma ') + this.utils.tzAbbr(sess.start),
        duration: this.utils.toDuration(sess.duration),
        closed: this.utils.now().isAfter(dayjs.unix(sess.start + 7200)),
        startTime: sess.start,
        language: p.language || 'en',
        locked: p.locked
      };

      // if (p.programId === 5683) {
      //   program.locked = true;
      // }

      if (p.programTypeProp.displayName === 'Volunteer Meet') {
        this.sessionType = VolunteerMeets.find((w: SessionType) => w.programType === p.subtitle);
        if (!this.sessionType) {
          return;
        }
        this.hideRegistrationTitle = this.sessionType.hideRegistrationTitle;
        this.hideProgramDetails = this.sessionType.hideProgramDetails;
        program.title = `${this.sessionType.displayName || this.sessionType.programType}`;
      } else if (p.programTypeProp.displayName === 'Orientation Webinar') {
        this.sessionType = OrientationWebinars.find((w: SessionType) => w.programType === p.subtitle);
        if (!this.sessionType) {
          return;
        }
        this.hideRegistrationTitle = this.sessionType.hideRegistrationTitle;
        this.hideProgramDetails = this.sessionType.hideProgramDetails;
        program.title = `${this.sessionType.displayName || this.sessionType.programType}`;
      } else {
        this.sessionType = SessionTypes.find((w: SessionType) => w.programType === p.programTypeProp.displayName);
        if (
          this.sessionType.preconditionPracticeIds &&
          !this.utils.intersects(this.user.practiceIds, this.sessionType.preconditionPracticeIds)
        ) {
          this.notAllowed = true;
        } else if (this.sessionType.preconditionProgram === 'Bhava Spandana') {
          this.checkPractice = 'Bhava Spandana';
        }
      }

      this.programs.push(program);
      this.numOpenPrograms++;
    });
    this.programs.sort((a, b) => a.startTime - b.startTime);

    this.program = this.programs[0];
    this.submissionKey = `IC-Submitted-${this.program.programId}-${this.user.memberId}`;
    this.programDetailsSubmitted = !!localStorage.getItem(this.submissionKey);
  }

  checkRegistration(programIds: number[]) {
    this.backend.api('portal.getRegisteredPrograms', { programIds }).subscribe((rsp: ApiResponseModel) => {
      if (rsp.statusCode === 'SUCCESS') {
        const programs = rsp.object;
        if (programs && programs.length > 0) {
          this.registeredProgram = find(this.programs, { programId: programs[0].programId });
        }
      }

      if (this.checkPractice) {
        this.backend.api('portal.checkPractice', { memberId: this.user.memberId, practice: this.checkPractice })
          .subscribe((rsp2: ApiResponseModel) => {
            this.notAllowed = !rsp2.object;
            this.isInit = true;
          });
      } else {
        this.isInit = true;
      }
    });
  }

  register(program) {
    program.submitting = true;
    this.submitting = true;

    const params = {
      programId: program.programId,
      memberId: this.user.memberId,

      // @ts-ignore
      successCallbackUrl: this.platformLocation.location.origin
    };

    this.backend
      .api('portal.register', params)
      .pipe(
        finalize(() => {
          program.submitting = false;
          this.submitting = false;
        })
      )
      .subscribe((rsp: ApiResponseModel) => {
        if (rsp.statusCode === 'SUCCESS') {
          this.registeredProgram = find(this.programs, { programId: program.programId });
        }
      });
  }

  submitDetails(f: NgForm) {
    const params = {
      name: this.name,
      emailAddress: this.user.email,
      altEmailAddress: this.altEmailAddress,
      memberId: this.user.memberId,
      phoneNumber: this.user.phoneNumber,
      programDetails: f.value.programDetails,
      sheetName: this.sessionType.gSheetName,
      country: this.user.country
    };

    this.submitting = true;
    this.backend
      .gformSubmit('AKfycbyPQqSsy4MUg7ucK3aWHdwZOeEYnVuKudTcsx3tIMQpcomAYNC5A2SBnNiMhFqYrMWW', params)
      .pipe(
        finalize(() => {
          this.submitting = false;
        })
      )
      .subscribe((rsp: ApiResponseModel) => {
        localStorage.setItem(this.submissionKey, 'true');
        this.programDetailsSubmitted = true;
      });
  }
}
