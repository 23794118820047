export interface SessionType {
  programType: string;
  orientationWebinar?: boolean;
  volunteerMeet?: boolean;
  sadhanaSession?: boolean;
  displayName?: string;
  description?: string;
  img?: string;
  preconditionPracticeIds?: number[];
  preconditionPractices?: string[];
  preconditionProgram?: string;
  gSheetName?: string;
  hideInGettingInvolved?: boolean;
  hideRegistrationTitle?: boolean;
}

export const SessionTypes: SessionType[] = [
  {
    programType: 'Being a Volunteer',
    displayName: 'Being a Volunteer (Repeat)',
    orientationWebinar: true,
    description:
      'Join us to know more on how you can get involved!'
  },
  {
    programType: 'Bhava Spandana - Post Program Meetup',
    displayName: 'Bhava Spandana - Post Program Meetup',
    orientationWebinar: true,
    preconditionProgram: 'Bhava Spandana',
    description:
      'Open to all, who have attended and volunteered for the recent Bhava Spandana programs in Australia.',
    gSheetName: 'Bhava Spandana',
    // hideInGettingInvolved: true,
  },
  {
    programType: 'Deepening Hatha Yoga',
    orientationWebinar: true,
    description:
      'Using this body as a vehicle to access deeper dimensions of life is what science of hatha yoga is ' +
      'all about.'
  },
  {
    programType: 'Isha Vidhya Orientation Webinar',
    orientationWebinar: true,
    description: 'An opportunity to illuminate and transform a child\'s life!'
  },
  {
    programType: 'Sanghamitra Orientation Webinar',
    orientationWebinar: true,
    displayName: 'The Story of our Sangha (spiritual community)',
    description: 'Sanghamitra Orientation. Join us in crafting a conscious planet.'
  },
  {
    programType: 'My Inner Engineering Story - Sharing Workshop',
    orientationWebinar: true,
    description: 'How your story can inspire the world!'
  },
  {
    programType: 'Online Yoga Veera Training',
    orientationWebinar: true,
    description: 'We invite you to become a part of Sadhguru\'s vision and know the joy of touching lives.'
  },
  {
    programType: 'Online Yoga Veera Training - Part 2',
    orientationWebinar: true,
    description: 'Pre-requisite: Completion of Online Yoga Veera Training Part 1 is required to attend this training.'
  },
  {
    programType: 'Digital Veera Training - Level 1',
    displayName: 'Social Media Training',
    orientationWebinar: true,
    description:
      'When Gossip is Global, Time for Truth to Go Viral, to knock on minds and hearts and awaken the innate ' +
      'longing of every human being to seek what is highest.'
  },
  {
    programType: 'Introduction to Sadhanapada',
    orientationWebinar: true,
    description: 'Webinar includes glimpses of Sadhanapada program, alumni stories and Q&A session.'
  },
  {
    programType: 'Birth of Dhyanalinga',
    sadhanaSession: true
  },
  {
    programType: 'Sages of India - Part 1',
    sadhanaSession: true,
    description:
      'In this webinar, we explore the inspiring, humbling and heartwarming lives of some of India\'s prominent sages. ' +
      'This webinar also includes the Bhakti Sadhana process, which one can take with them and continue to ' +
      'practice on their own.'
  },
  {
    programType: 'Sages of India - Part 2',
    sadhanaSession: true,
    description:
      "In this webinar, we explore the inspiring, humbling and heartwarming lives of some of India's prominent sages. " +
      'This webinar also includes the Bhakti Sadhana process, which one can take with them and continue to ' +
      'practice on their own.'
  },
  {
    programType: 'Satsang',
    sadhanaSession: true
  },
  {
    programType: 'Bhairavi Sadhana',
    sadhanaSession: true
  },
  {
    programType: 'Shambhavi Mahamudra Practice Q&A',
    sadhanaSession: true
  },
  // {
  //   programType: 'Shambhavi Practice Correction',
  //   sadhanaSession: true
  // },
  {
    programType: 'Shakti Chalana Kriya Practice Q&A',
    sadhanaSession: true,
    preconditionPracticeIds: [18, 19, 21], // SCK, Shoonya, Samyama
    preconditionPractices: ['Shakti Chalana Kriya'],
    gSheetName: 'SCK QnA'
  },
  {
    programType: 'Corporate Offering Workshop',
    orientationWebinar: true,
    description: "Join us to take Sadhguru's offerings to your organisation"
  },
  {
    programType: 'BEYOND Inner Engineering: An Introduction',
    orientationWebinar: true,
    description: 'Online webinar with trained Inner Engineering Ishanga (instructor)',
    img: 'beyondinnerengineering.jpg'
  },
  {
    programType: 'Bond of Grace',
    displayName: 'Introduction to Ishanga 7% - Bond of Grace',
    orientationWebinar: true,
    description: 'We welcome you to join a free online webinar to know more about Ishanga 7% partnership.',
    img: 'bond-of-grace.png'
  },
  {
    programType: 'Ananda Alai - Introduction Webinar',
    displayName: 'Ananda Alai - An Introduction',
    orientationWebinar: true,
    description:
      'A brief session for you to understand the Ananda Alai program better and get your questions related to this program clarified.',
    img: 'ananda-alai-webinar.jpg'
  },
  {
    programType: 'Sadhguru Exclusive Trivia',
    orientationWebinar: true,
    description: "Explore a year's journey into mystical realms with us through various games & activities.",
    img: 'sgex-trivia.jpeg'
  },
  {
    programType: 'Save Soil - Earth Buddy Training',
    displayName: 'Earth Buddy Training',
    orientationWebinar: true,
    description: 'Become earth buddy and conduct introduction sessions to conscious planet movement.',
    img: 'planet-champion.jpg'
  },
  {
    programType: 'Friends of Soil - A Webinar',
    orientationWebinar: true,
    description: 'Get together with fellow Earth Buddies for some fun and much more :)',
    img: 'fos.jpeg'
  },
  {
    programType: '  Earth Buddy Celebration',
    orientationWebinar: true,
    description: 'Get together with fellow Earth Buddies for some fun and much more :)',
    img: 'EB-Celebrations.jpeg'
  },
  {
    programType: 'Save Soil - Create Pledge Video Workshop',
    orientationWebinar: true,
    description: 'Create your own inspiring videos.',
    img: 'video-workshop.jpg'
  },
  {
    programType: 'Save Soil Corporate & Partnership Outreach',
    orientationWebinar: true,
    description: 'Your company or organization could become a Save Soil partner, learn how!',
    img: 'cp_ssc_po.jpeg'
  },
  {
    programType: 'Naga Pratishtha Webinar',
    orientationWebinar: true,
    description:
      'This is an invitation to know more about the unparalleled opportunity to participate in a ' +
      'consecration by Sadhguru, in person at IYC Bengaluru',
    img: 'naga-pratishtha-webinar.png'
  },
  {
    programType: 'MahaShivRatri Webinar',
    orientationWebinar: true,
    description: 'A Night with the Divine! Join us to know more on how you can make the best use of this exuberant night!',
    img: 'msr-webinar.jpg'
  }
];

export const VolunteerMeets = [
  {
    displayName: 'MahaShivRatri 2023 - Meditator Meet',
    programType: 'MahaShivRatri Meditator Meet',
    description:
      'If you thought this is another run of the mill meet, you are mistaken! MahaShivRatri is the only ' +
      'festival that is a 12 hour nightlong celebration with so many people up on their feet the whole night full ' +
      'with energy. It is in our hands to make this grandest online celebration available to everyone possible!',
    img: 'msr-meet.jpg'
  },
  {
    displayName: 'Meet for Isha Covid Action',
    programType: 'Isha Covid Action', // Should match the subtitle field in CRM Program
    img: 'meet-for-isha-covid-action.jpg',
    description: 'Support our Urgent Need'
  },
  {
    displayName: 'Volunteer Meet for Karma Book (Asia)',
    programType: 'Karma Book (SEA)', // Should match the subtitle field in CRM Program
    img: 'karmabook-sea.jpg',
    description: 'A first time unique opportunity!'
  },
  {
    displayName: 'NEW Avatar of Isha Offerings',
    programType: 'New Avatar of Isha Offerings', // Should match the subtitle field in CRM Program
    img: 'newishaofferings.jpg',
    description: ' Be a part of the change :)'
  },
  {
    displayName: 'Volunteer Meet for IDY 2021',
    programType: 'Volunteer Meet for IDY 2021', // Should match the subtitle field in CRM Program
    img: 'idy-vol-meet.jpg',
    description: 'Lets make International Day of Yoga 2021 huge!'
  },
  {
    displayName: 'Meditators Meet - Important Updates',
    programType: 'Meditators Meet: Important Updates on Inner Engineering',
    img: 'mm.jpg'
  },
  {
    displayName: 'Join us TODAY',
    programType: 'Join us TODAY',
    img: 'sia-jan15-meet.jpg',
    hideRegistrationTitle: true,
    hideInGettingInvolved: true
  },
  {
    displayName: 'Introduction to Conscious Planet - Live',
    programType: 'Introduction to Conscious Planet - Live', // Should match the subtitle field in CRM Program
    img: 'conscious-planet.jpg',
    description:
      'An initiative to raise individual human consciousness and instil a sense of inclusiveness in humanity. ' +
      'A movement of massive proportions never before seen in Isha. The involvement of every single Isha meditator ' +
      'is needed to make this happen. Join this webinar to find out more.'
  },
  {
    displayName: '100DaysForSoil Asia Pacific Earth Buddy Meet',
    programType: '100DaysForSoil Asia Pacific Earth Buddy Meet', // Should match the subtitle field in CRM Program
    img: 'cp_100daysforsoil.png',
    description: 'Join us for the Asia Pacific Earth Buddy Meet - Ride with Sadhguru'
  },
  {
    displayName: 'Going all out for SiA now!',
    programType: 'Going all out for SiA now!', // Should match the subtitle field in CRM Program
    hideRegistrationTitle: true,
    hideInGettingInvolved: true
  },
  // {
  //   displayName: 'Introduction to the Save Soil Movement',
  //   programType: 'Introduction to the Save Soil Movement', // Should match the subtitle field in CRM Program
  //   img: 'CP_SS.jpeg',
  //   description:
  //     'Get started as an Earth Buddy! Join us in this interactive session to know how you can be an Earth Buddy to Save Soil. '
  // },
  // {
  //   displayName: 'Introduction to the Save Soil Movement - In Mandarin',
  //   programType: 'Introduction to the Save Soil Movement - In Mandarin', // Should match the subtitle field in CRM Program
  //   img: 'CP_SS.jpeg',
  //   description:
  //     'Get started as an Earth Buddy! Join us in this interactive session to know how you can be an Earth Buddy to Save Soil. '
  // },
  // {
  //   displayName: 'Introduction to the Save soil movement - In Vietnamese',
  //   programType: 'Introduction to the Save soil movement - In Vietnamese', // Should match the subtitle field in CRM Program
  //   img: 'CP_SS.jpeg',
  //   description:
  //     'Get started as an Earth Buddy! Join us in this interactive session to know how you can be an Earth Buddy to Save Soil. '
  // },
  {
    displayName: 'Introduction to Conscious Planet - Re-Telecast',
    programType: 'Introduction to Conscious Planet - Re-Telecast', // Should match the subtitle field in CRM Program
    img: 'conscious-planet.jpg',
    description:
      'An initiative to raise individual human consciousness and instil a sense of inclusiveness in humanity. ' +
      'A movement of massive proportions never before seen in Isha. The involvement of every single Isha meditator ' +
      'is needed to make this happen. Join this webinar to find out more.'
  }
];
